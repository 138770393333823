import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const NaimytskiTaZarobitchanskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "naimytski-ta-zarobitchanski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Наймитські та заробітчанські пісні"
        description="Наймитські пісні — тематичний цикл станової суспільно-побутової народної лірики, що відображає настрої, умови побуту і соціальний стан наймитів у селянських господарствах."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Наймитські та заробітчанські пісні
          </h1>
          <p className="typo-body mt-4 max-w-3xl">
            Наймитські пісні — тематичний цикл станової суспільно-побутової народної лірики, що відображає настрої,
            умови побуту і соціальний стан наймитів у селянських господарствах.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список наймитських та заробітчанських пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру наймитської та заробітчанської пісні
        </h2>
        <p className="typo-body mt-4">
          Наймитські та заробітчанські пісні тематично поєднані з бурлацькими. В них теж ідеться про долю наймита,
          «що робить, аж піт очі заливає, а хазяїн його лає». Бідняки, що домовлялися працювати на хазяїна певний строк,
          потрапляли в найтяжчу кабалу:
        </p>
        <p className="typo-body italic mt-4">
          «Ой матінко-зірко, як у строку гірко —
        </p>
        <p className="typo-body mt-4">
          звертається до матері дівчина-наймичка, — ні їсти, ні пити, ні сісти спочити...» У відчаї вона готова прийняти
          смерть: «ти, сира земелька, прийми отця й неньку й мене, молоденьку, щоб я не ходила, в наймах не служила,
          чужої роботи тяжко не робила...»
        </p>
        <p className="typo-body mt-4">
          Наймита зневажають, називають «неробою», його годують цвілими сухарями, гонять після тяжкої роботи на полі ще
          й носити воду, пасти волів:
        </p>
        <p className="typo-body italic mt-4">
          Приймай, жінко, з стола вареники,
          <br />
          Бо йде наймитище.
          <br />
          Постав йому вчорашнього борщу,
          <br />
          Нехай він похлище.
        </p>
        <p className="typo-body mt-4">
          Але й це ще не найбільше горе, буває, що хазяїн спроваджує наймита в солдати замість свого сина: «Ой у лузі та
          при березі зацвіла калина... Не за кого піду я в солдати — за хазяйського сина!»
        </p>
        <p className="typo-body mt-4">
          Наймитські та заробітчанські пісні тематично пов'язані з кріпацькими, чумацькими й солдатськими піснями. У
          творах пізнішого походження з'являються мотиви, що єднають їх з робітничим фольклором. Прикладом такого
          перехідного стану від традиційної селянської лірики до робітничого фольклору може служити пісня "Як наймали
          вговоряли". Маршований ритм, приспів (Ей, раз, два, люлі) взято із солдатської пісенності, зміст традиційний
          для соціально-побутової лірики,- це бідування строкарів, яким "хліба-солі не дали", а годували буряками. Але
          нове - у лексичних зворотах, запозичених з робітничого оточення, в оптимістичному, мажорному звучанні й
          вираженні відкритого протесту: "стягнем пана з подушок, возьмем його за петельки - оддай дєньги за копєйки".
        </p>
        <p className="typo-body mt-4">
          Великий цикл складають заробітчанські пісні про еміграцію селян Буковини Галичини, західного Поділля і
          Закарпаття в кінці XIX поч. XX ст. до Америки. Покинувши рідну домівку і клаптик землі, що не міг прогодувати
          сім'ю, емігранти не завжди знаходили полегшення на чужині, яка часто "не одного господаря з торбами пустила".
        </p>
      </div>
    </>
  );
};

export default NaimytskiTaZarobitchanskiPisniPage;
